
b-modal(centered, v-model="show")
  template(slot="modal-title")
    .modal-title {{ $t('companies.storages.sftp.title') }}
    div(v-if="isCreate")
      .modal-subtitle {{ $t('companies.storages.sftp.create') }}
    div(v-else)
      .modal-subtitle {{ $t('companies.storages.sftp.update') }}
  .position-relative
    b-form-group(
      :label="$t('companies.storages.sftp.name',)",
      :invalid-feedback="errors.first('name')",
      :state="errors.has('name') ? false : null",
      )
      b-input(
        v-model="form.name",
        v-validate="'required'",
        data-vv-name="name",
      )
    stack
      stack-item(fill)
        b-form-group(
          :label="$t('companies.storages.sftp.host')",
          label-for="sftp-host-input",
          label-cols="5",
          :invalid-feedback="errors.first('host')",
          :state="errors.has('host') ? false : null",
        )
          b-input#sftp-host-input(
          v-model="form.host",
          v-validate="'required'",
          data-vv-name="host",
          )
      stack-item
        b-form-group(
          :label="$t('companies.storages.sftp.port')",
          label-for="sftp-port-input",
          label-cols="3",
          :invalid-feedback="errors.first('port')",
          :state="errors.has('port') ? false : null",
        )
          b-input#sftp-port-input(
          v-model="form.port",
          placeholder="22",
          v-validate="'required'",
          data-vv-name="port",
          )
    b-form-group(
      :label="$t('companies.storages.sftp.username')",
      label-for="sftp-username-input",
      label-cols="3",
      :invalid-feedback="errors.first('username')",
      :state="errors.has('username') ? false : null",
    )
      b-input#sftp-username-input(
        v-model="form.username",
        v-validate="'required'",
        data-vv-name="username",
      )
    b-form-group(
      :label="$t('companies.storages.sftp.password')",
      label-for="sftp-password-input",
      label-cols="3",
      :invalid-feedback="errors.first('password')",
      :state="errors.has('password') ? false : null",
    )
      b-form-input#sftp-password-input(
        v-model="form.password",
        type="password",
        data-vv-name="password",
        v-validate="{ required: this.isCreate }",
      )
    b-form-group(
      :label="$t(`companies.storages.sftp.default`)",
      label-for="default-input",
      label-cols="4"
    )
      b-form-checkbox#default-input(
        v-model="form.default",
        switch
      )
    stack(v-if="sftpError")
      stack-item(fill)
        .alert.alert-danger SFTP connection error: {{ sftpError }}
    b-form-group(:label="$t('companies.storages.sftp.path')" v-if="!isCreate")
      stack
        stack-item
          b-btn(
            :disabled="form.remote_path === './'",
            variant="primary",
            squared,
            @click="SFTPFolderUp"
          )
            .mdi.mdi-folder-upload-outline
        stack-item(fill)
          b-input(v-model="form.remote_path", :readonly="true")
        stack-item
          b-btn(variant="flat-primary", squared, @click="save")
            .mdi.mdi-folder-network-outline
            | {{ $t('companies.storages.sftp.connect') }}
      b-form-select.mt-3(
        v-model="sftpSelectedFolder",
        :disabled="sftpFolders.length === 0",
        :options="sftpFolders",
        :loading="inProgress",
        :select-size="sftpFolders.length + 1"
      )
    .form-overlay(
      v-if="inProgress"
    )
      b-spinner(variant="success")

  template(slot="modal-footer")
    b-btn(
      variant="primary",
      squared,
      @click="save(true)"
    )
      .mdi.mdi-folder-outline
      | {{ $t('actions.save') }}
