<template lang="pug">
b-modal(centered, v-model="show")
  template(slot="modal-title")
    .modal-title {{ $t('companies.storages.sftp.title') }}
    div(v-if="isCreate")
      .modal-subtitle {{ $t('companies.storages.sftp.create') }}
    div(v-else)
      .modal-subtitle {{ $t('companies.storages.sftp.update') }}
  .position-relative
    b-form-group(
      :label="$t('companies.storages.sftp.name',)",
      :invalid-feedback="errors.first('name')",
      :state="errors.has('name') ? false : null",
      )
      b-input(
        v-model="form.name",
        v-validate="'required'",
        data-vv-name="name",
      )
    stack
      stack-item(fill)
        b-form-group(
          :label="$t('companies.storages.sftp.host')",
          label-for="sftp-host-input",
          label-cols="5",
          :invalid-feedback="errors.first('host')",
          :state="errors.has('host') ? false : null",
        )
          b-input#sftp-host-input(
          v-model="form.host",
          v-validate="'required'",
          data-vv-name="host",
          )
      stack-item
        b-form-group(
          :label="$t('companies.storages.sftp.port')",
          label-for="sftp-port-input",
          label-cols="3",
          :invalid-feedback="errors.first('port')",
          :state="errors.has('port') ? false : null",
        )
          b-input#sftp-port-input(
          v-model="form.port",
          placeholder="22",
          v-validate="'required'",
          data-vv-name="port",
          )
    b-form-group(
      :label="$t('companies.storages.sftp.username')",
      label-for="sftp-username-input",
      label-cols="3",
      :invalid-feedback="errors.first('username')",
      :state="errors.has('username') ? false : null",
    )
      b-input#sftp-username-input(
        v-model="form.username",
        v-validate="'required'",
        data-vv-name="username",
      )
    b-form-group(
      :label="$t('companies.storages.sftp.password')",
      label-for="sftp-password-input",
      label-cols="3",
      :invalid-feedback="errors.first('password')",
      :state="errors.has('password') ? false : null",
    )
      b-form-input#sftp-password-input(
        v-model="form.password",
        type="password",
        data-vv-name="password",
        v-validate="{ required: this.isCreate }",
      )
    b-form-group(
      :label="$t(`companies.storages.sftp.default`)",
      label-for="default-input",
      label-cols="4"
    )
      b-form-checkbox#default-input(
        v-model="form.default",
        switch
      )
    stack(v-if="sftpError")
      stack-item(fill)
        .alert.alert-danger SFTP connection error: {{ sftpError }}
    b-form-group(:label="$t('companies.storages.sftp.path')" v-if="!isCreate")
      stack
        stack-item
          b-btn(
            :disabled="form.remote_path === './'",
            variant="primary",
            squared,
            @click="SFTPFolderUp"
          )
            .mdi.mdi-folder-upload-outline
        stack-item(fill)
          b-input(v-model="form.remote_path", :readonly="true")
        stack-item
          b-btn(variant="flat-primary", squared, @click="save")
            .mdi.mdi-folder-network-outline
            | {{ $t('companies.storages.sftp.connect') }}
      b-form-select.mt-3(
        v-model="sftpSelectedFolder",
        :disabled="sftpFolders.length === 0",
        :options="sftpFolders",
        :loading="inProgress",
        :select-size="sftpFolders.length + 1"
      )
    .form-overlay(
      v-if="inProgress"
    )
      b-spinner(variant="success")

  template(slot="modal-footer")
    b-btn(
      variant="primary",
      squared,
      @click="save(true)"
    )
      .mdi.mdi-folder-outline
      | {{ $t('actions.save') }}
</template>

<script>
import ApiCompanyStorages from "@/api/companyStorages.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      isCreate: false,
      companyId: null,
      form: {
        remote_path: null
      },
      sftpFolders: [],
      sftpSelectedFolder: null,
      sftpError: null,
      inProgress: false
    };
  },

  watch: {
    sftpSelectedFolder: function (value) {
      if (value) {
        this.changeSelectedSFTPFolder(value);
      }
    },
  },

  methods:{
    open(companyId, sftp) {
      if (sftp) {
        this.isCreate = false;
        this.form = {
          id: sftp.id,
          name: sftp.name,
          default: sftp.default,
          host: sftp.host,
          port: sftp.port,
          remote_path: sftp.remote_path,
          username: sftp.username
        };
        this.form.remote_path = sftp.remote_path
      } else {
        this.isCreate = true;
        this.companyId= null;
        this.form = {};
        this.sftpSelectedFolder = null;
        this.form.remote_path = null;
      }
      this.sftpFolders = [];
      this.sftpError = null;
      this.companyId = companyId;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save(closeAfterSave = true) {
      this.$validator.validateAll().then((res) => {
        if (!res) return;
        this.inProgress = true;
        if(this.isCreate) {
          this.createSFTP(closeAfterSave).then(() => {
            this.SFTPFolderUp()
          })
        } else {
          return this.updateSFTP(closeAfterSave)
        }
      });
    },


    updateSFTP(closeAfterSave) {
      let storageData = {
        storage: this.form,
      };
      this.$validator.validateAll().then((res) => {
        return ApiCompanyStorages.updateSFTP(this.$route.params.id, this.form.id, storageData).then(
            (resp) => {
              if (resp.data.sftp) {
                this.getData(resp.data.sftp.id);
                this.form.id = resp.data.sftp.id;
                if(closeAfterSave == true) {
                  this.close();
                  this.$emit('done');
                }
              }
              this.sftpError = resp.data.error;
              this.inProgress = false;
            }
        );
      });
    },
    createSFTP(closeAfterSave) {
      let storageData = {
        storage: this.form,
      };
      this.$validator.validateAll().then((res) => {
        return ApiCompanyStorages.createSFTP(this.$route.params.id, storageData).then(
            (resp) => {
              if (resp.data.sftp) {
                this.getData(resp.data.sftp.id);
                this.form.id = resp.data.sftp.id;
                if(closeAfterSave == true) {
                  this.close();
                  this.$emit('done');
                }
              }
              this.sftpError = resp.data.error;
              this.inProgress = false;
            }
        );
      });
    },
    SFTPFolderUp() {
      if(this.form.id == undefined){
        this.sftpError = 'Create SFTP connection first'
        return;
      }
      if (this.form.remote_path == "./") {
        return;
      }
      this.form.remote_path = this.form.remote_path.split("/").slice(0, -1).join("/");
      if (this.form.remote_path === ".") {
        this.form.remote_path = "./";
      }
      this.inProgress = true;
      ApiCompanyStorages.SFTPFolderList(
          this.$route.params.id,
          this.form.remote_path,
          this.form.id
      ).then((resp) => {
        this.sftpFolders = resp.data.folders;
        this.getData(this.form.id)
        this.inProgress = false
      });
    },

    changeSelectedSFTPFolder(folder) {
      this.inProgress = true;
      let path =
          this.form.remote_path === "./"
              ? `${this.form.remote_path}${this.sftpSelectedFolder}`
              : `${this.form.remote_path}/${this.sftpSelectedFolder}`;
      ApiCompanyStorages.SFTPFolderList(this.$route.params.id, path, this.form.id).then(
          (resp) => {
            this.sftpFolders = resp.data.folders;
            this.form.remote_path = path;
            this.inProgress = false;
            this.sftpSelectedFolder = null;
          }
      );
    },

    getData(sftp_id) {
      ApiCompanyStorages.SFTPFolderList(this.$route.params.id, this.form.remote_path, sftp_id).then(
          (resp) => {
            this.sftpFolders = resp.data.folders;
            this.sftpError = resp.data.error;
          }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-overlay {
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
    background: rgba(white, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>